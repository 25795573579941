export const userUrl = {
  // login
  userPermissions: "institute/user/myPermission",
  login: "institute/user/login",
  dashboard: "institute/dashboard",
  defaultSession: "institute/session/defaultSession",
  master: "institute",
  masterData: "institute/assignFees/new",
  // session
  session: "institute/session",

  // asign fees
  addFees: "institute/assignFees/add",
  feesList: "institute/assignFees",
  // student
  getLookFees: "institute/assignFees/lookFees",
  studentList: "institute/student",
  studentUpdate: "institute/student/update",
  bulkUpload: "institute/student/bulkUpload",
  studentAdmission: "institute/student/add",
  checkSrAndAdm: "institute/student/checkExit",
  tcIssue: "institute/student/tcIssued",

  // fees
  newCollect: "institute/fees/newCollect",
  studentFees: "institute/fees/getStudentFee",
  feesCollectEdit: "institute/fees/feesCollectEdit",
  feesCollect: "institute/fees/feesCollect",
  feesCollectUpdate: "institute/fees/feesCollectUpdate",
  collectionReport: "institute/fees/feesCollectReport",
  newCollectionReport: "institute/fees/collectionReport",
  deleteCollectReport: "institute/fees/feesCollectStatusUpdate",
  // cancel report
  cancelReport: "institute/fees/cancelReport",
  //
  // ledger
  studentLedger: "institute/fees/feesLedger",
  // pending fees

  pendingClassFees: "institute/fees/pendingClassFees",
  pendingSingleClassFees: "institute/fees/pendingSingleClassFees",
  // fees update
  studentFeesSave: "institute/fees/studentFeesSave",
  studentFeesEdit: "institute/fees/studentFeesUpdate",

  // cheque status
  chequeList: "institute/fees/chequeList",
  addCheque: "institute/fees/chequeStatus/add",

  // bank
  bank: "institute/bank",
  // role
  role: "institute/role",
  // permssions
  permissionUpdate: "institute/permissionAccess/update",
  rolePermission: "institute/permissionAccess",
  // user
  user: "institute/user",

  // session

  // school profile
  school: "institute/user/mySchool",
  updateSchool: "institute/user/updateSchool",
  // forgot password
  forgotPassword: "institute/user/forgotPassword",
  resetPassword: "institute/user/passwordReset",

  // recept
  getReceptData: "institute/fees/singleReport",
  // discount
  discount: "institute/assignDiscount",
  // change password
  changePassword: "institute/user/changePassword",

  // apply for payment gateway
  applyForPaymentGateWay: "institute/gateway/apply",
  paymentGateWayList: "institute/gateway",
  studentPromotion: "institute/promotion",
};

export const webUrl = {
  state: "website/state",
  district: "website/district",
};

export const adminUrl = {
  getOrganisationList: "admin/school",
  createPlan: "admin/plan/add",
  plans: "admin/plan",
  enquiry: "admin/enquiry",
  // bank
  bank: "admin/bank",
  paymentGateWay: "admin/paymentGateWay",
  singlePaymentRequest: "admin/paymentGateWay",
};
