import { createContext, useEffect, useState } from "react";
import {
  getLocaleStorageItem,
  setLocaleStorageItem,
} from "../globle/utils/localeStorage";

export const SessionContext = createContext();

function SessionProvider({ children }) {
  const [sessionList, setSessionList] = useState([]);
  const [currentSession, setCurrentSession] = useState("");
  const [sessionData, setSessionData] = useState(
    getLocaleStorageItem("activeSession") || { isDefault: 0, id: 0 }
  );

  const sessionId = sessionData.id;

  useEffect(() => {
    setLocaleStorageItem("activeSession", sessionData);
  }, [sessionData]);

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        sessionList,
        setSessionList,
        currentSession,
        setCurrentSession,
        setSessionData,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
export default SessionProvider;
