import axios from "axios";
import swal from "sweetalert";
import {
  adminLoginConfirmAction,
  adminLogout,
  loginConfirmedAction,
  Logout,
} from "../store/actions/AuthActions";
import {
  getLocaleStorageItem,
  removeLoaleStorageItem,
  setLocaleStorageItem,
} from "../globle/utils/localeStorage";
import userApi from "../globle/services/api's";
import { BASE_URL } from "../globle/services";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(orgCode, mobileNo, password) {
  const postData = {
    orgCode,
    mobileNo,
    password,
  };
  return axios.post(`${BASE_URL}/institute/user/login`, postData);
}
export function adminLogin(mobileNo, password) {
  const postData = {
    mobileNo,
    password,
  };
  return axios.post(`${BASE_URL}/admin/user/login`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      break;
    case "Email not found!!":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Incorrect email and password combination !!":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

async function setPermissions(dispatch) {
  try {
    const res = await userApi.userPermissions();
    const userDetails = getLocaleStorageItem("userDetails");

    const updatedUserDetails = {
      ...userDetails,
      permission: res.data.permission,
      gatwayStatus: res.data.gatwayStatus,
    };
    setLocaleStorageItem("userDetails", updatedUserDetails);

    const data = {
      mobileNo: res.data.userData.mobileNo,
      idToken: res.data.userData.authToken,
      permissions: res.data.permission,
    };

    dispatch(loginConfirmedAction(data));
  } catch (err) {}
}

export function checkAutoLogin(
  dispatch,
  navigate,
  setCurrentSession,
  setSessionData
) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  } else {
    setDefaultSession();
    setPermissions(dispatch);
  }
  async function setDefaultSession() {
    try {
      const res = await userApi.defaultSession();
      if (res.data.action && res.data.data.name) {
        setCurrentSession({
          name: res.data.data.name,
          id: res.data.data.id,
          startDate: res.data.data.startDate,
          endDate: res.data.data.endDate,
        });
        setSessionData({ isDefault: 1, id: res.data.data.id });
      } else {
        setCurrentSession("");
        setLocaleStorageItem("activeSession", { isDefault: 0, id: 0 });
      }
    } catch (error) {}
  }
}
export function checkAdminAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("adminDetails");

  if (!tokenDetailsString) {
    dispatch(adminLogout(navigate));
    return;
  }
  const data = {
    mobileNo: JSON.parse(tokenDetailsString).userData.mobileNo,
    accessToken: JSON.parse(tokenDetailsString).accessToken,
  };
  removeLoaleStorageItem("userDetails");
  dispatch(adminLoginConfirmAction(data));
}

export function isLogin() {
  const tokenDetailsString = localStorage.getItem("userDetails");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
