import axios from "axios";
import { getLocaleStorageItem } from "../utils/localeStorage";
export const IMAGE_BASE_URL = "https://api.feespe.com/";

export const BASE_URL = "https://api.feespe.com/api";

const createApiForClient = (contentType) => {
  const ROUTE = window.location.pathname.toLowerCase();

  let accessToken = "";

  const headers = { "Cache-Control": "no-cache" };

  if (contentType === "multipart") {
    headers["Content-Type"] = "multipart/form-data";
  } else if (contentType === "json") {
    headers["Content-Type"] = "application/json";
  }

  const api = axios.create({
    baseURL: BASE_URL,
    timeout: 600000,
  });

  // Request interceptor
  api.interceptors.request.use(
    function (config) {
      if (ROUTE.includes("admin")) {
        accessToken = getLocaleStorageItem("adminDetails")?.accessToken;
      } else {
        accessToken = getLocaleStorageItem("userDetails")?.userData.authToken;
      }
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return api;
};

const apiMultipart = createApiForClient("multipart");
const apiJson = createApiForClient("json");

export { apiMultipart, apiJson };
