import swal from "sweetalert";
import {
  adminLogin,
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import { setLocaleStorageItem } from "../../globle/utils/localeStorage";
import userApi from "../../globle/services/api's";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";

export function signupAction(MobileNo, password, navigate) {
  return (dispatch) => {
    signUp(MobileNo, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate("/institute");
        //history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate, whereToNavigate) {
  localStorage.removeItem("userDetails");
  if (whereToNavigate) {
    navigate(whereToNavigate);
  } else {
    navigate("/login");
  }
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}
export function adminLogout(navigate) {
  localStorage.removeItem("adminDetails");
  navigate("/admin-Login");

  return {
    type: LOGOUT_ACTION,
  };
}

async function setDefaultSession(setCurrentSession, setSessionData) {
  try {
    const res = await userApi.defaultSession();
    if (res.data.action && res.data.data.name) {
      setCurrentSession({
        name: res.data.data.name,
        id: res.data.data.id,
        startDate: res.data.data.startDate,
        endDate: res.data.data.endDate,
      });
      setSessionData({ isDefault: 1, id: res.data.data.id });
    } else {
      setCurrentSession("");
      setLocaleStorageItem("activeSession", { isDefault: 0, id: 0 });
    }
  } catch (error) {}
}
export function loginAction(
  orgcode,
  mobileNo,
  password,
  navigate,
  setIsLoading,
  setCurrentSession,
  setSessionData
) {
  return (dispatch) => {
    login(orgcode, mobileNo, password)
      .then((response) => {
        setIsLoading(false);
        if (response.data.action) {
          setLocaleStorageItem("userDetails", response.data);
          setDefaultSession(setCurrentSession, setSessionData);

          localStorage.removeItem("adminDetails");
          const data = {
            mobileNo: response.data.userData.mobileNo,
            idToken: response.data.userData.authToken,
            permissions: response.data.permission,
          };

          dispatch(loginConfirmedAction(data));
          navigate("/");
        } else {
          swal("Oops", response.data.message, "error");
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}
export function adminLoginAction(mobileNo, password, navigate, setIsLoading) {
  return (dispatch) => {
    adminLogin(mobileNo, password)
      .then((response) => {
        setIsLoading(false);
        if (response.data.action) {
          setLocaleStorageItem("adminDetails", response.data);
          const data = {
            mobileNo: response.data.userData.mobileNo,
            accessToken: response.data.accessToken,
          };
          dispatch(adminLoginConfirmAction(data));
          navigate("/admin");
        } else {
          swal("Oops", response.data.message, "error");
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function adminLoginConfirmAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
