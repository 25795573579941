import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import Loader from "./jsx/components/MyLoader/Loader";
import SessionProvider from "./context/SessionContext";
import { ToastContainer } from "react-toastify";
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeContext>
        <SessionProvider>
          <Suspense fallback={<Loader height={"95vh"} color={"white"} />}>
            <ToastContainer autoClose={10000} placement="top-right" />
            <Routes>
              <Route
                path="/*"
                element={
                  <Suspense fallback={<Loader color={"white"} />}>
                    <App />
                  </Suspense>
                }
              ></Route>
            </Routes>
          </Suspense>
        </SessionProvider>
      </ThemeContext>
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
