import { apiJson, apiMultipart } from ".";
import { adminUrl, userUrl, webUrl } from "./uri";

const userApi = {
  dashboard: (payload) => apiJson.post(userUrl.dashboard, payload),
  defaultSession: () => apiJson.get(userUrl.defaultSession),
  userPermissions: () => apiJson.get(userUrl.userPermissions),
  checkSrAndAdm: (payload) => apiJson.post(userUrl.checkSrAndAdm, payload),
  // session

  session: () => apiJson.get(userUrl.session),
  addSession: (payload) => apiJson.post(userUrl.session + "/add", payload),
  changeSession: (payload) =>
    apiJson.put(userUrl.session + "/" + payload.id, payload.data),
  changeActiveSession: (payload) =>
    apiJson.put(userUrl.session + "/" + payload.id, payload.data),
  updateSession: (payload) =>
    apiJson.put(userUrl.session + "/" + payload.id, payload.data),

  deleteSession: (payload) =>
    apiJson.delete(userUrl.session + "/" + payload.id),

  // master
  master: (payload) => apiJson.get(userUrl.master + "/" + payload),
  // asign fees
  masterData: () => apiJson.get(userUrl.masterData),
  addFees: (payload) => apiJson.post(userUrl.addFees, payload),
  feesList: (payload) => apiJson.post(userUrl.feesList, payload),
  editFees: (payload) =>
    apiJson.put(userUrl.feesList + "/" + payload.id, payload.data),
  deleteFees: (payload) => apiJson.delete(userUrl.feesList + "/" + payload.id),
  // student
  studentList: (payload) => apiJson.post(userUrl.studentList, payload),
  getLookfees: (payload) => apiJson.post(userUrl.getLookFees, payload),
  studentUpdate: (payload) => apiMultipart.post(userUrl.studentUpdate, payload),
  deleteStudent: (payload) =>
    apiMultipart.delete(userUrl.studentList + "/" + payload.id),
  bulkUpload: (payload) => apiJson.post(userUrl.bulkUpload, payload),
  studentAdmission: (payload) =>
    apiMultipart.post(userUrl.studentAdmission, payload),
  tcIssue: (payload) => apiJson.post(userUrl.tcIssue, payload),
  // fees
  newCollect: (payload) => apiJson.post(userUrl.newCollect, payload),
  studentFees: (payload) => apiJson.post(userUrl.studentFees, payload),
  feesCollectEdit: (payload) => apiJson.post(userUrl.feesCollectEdit, payload),
  feesCollect: (payload) => apiJson.post(userUrl.feesCollect, payload),
  feesCollectUpdate: (payload) =>
    apiJson.post(userUrl.feesCollectUpdate, payload),

  // collection list
  collectionReport: (payload) =>
    apiJson.post(userUrl.collectionReport, payload),
  newCollectionReport: (payload) =>
    apiJson.post(userUrl.newCollectionReport, payload),

  // cancel report
  cancelReport: (payload) => apiJson.post(userUrl.cancelReport, payload),
  deleteCollectReport: (payload) =>
    apiJson.put(userUrl.deleteCollectReport, payload),
  // ledger
  studentLedger: (payload) => apiJson.post(userUrl.studentLedger, payload),
  // pending fees
  pendingClassFees: (payload) =>
    apiJson.post(userUrl.pendingClassFees, payload),
  pendingSingleClassFees: (payload) =>
    apiJson.post(userUrl.pendingSingleClassFees, payload),
  // fees update
  studentFeesSave: (payload) => apiJson.post(userUrl.studentFeesSave, payload),
  studentFeesEdit: (payload) => apiJson.put(userUrl.studentFeesEdit, payload),
  // cheque status
  chequeList: (payload) => apiJson.post(userUrl.chequeList, payload),
  addCheque: (payload) => apiJson.post(userUrl.addCheque, payload),
  // bank
  bank: () => apiJson.get(userUrl.bank),
  updateBank: (payload) =>
    apiJson.put(userUrl.bank + "/" + payload.bankId, payload.data),
  addBank: (payload) => apiJson.post(userUrl.bank + "/add", payload.data),
  deleteBank: (payload) => apiJson.delete(userUrl.bank + "/" + payload.id),

  // role
  role: () => apiJson.get(userUrl.role),

  // permissions
  permissionUpdate: (payload) =>
    apiJson.post(userUrl.permissionUpdate, payload),
  rolePermission: (payload) =>
    apiJson.get(userUrl.rolePermission + "/" + payload.id),
  // user
  user: () => apiJson.get(userUrl.user),
  createUser: (payload) =>
    apiMultipart.post(userUrl.user + "/add", payload.data),
  updateUser: (payload) =>
    apiMultipart.put(userUrl.user + "/" + payload.id, payload.data),
  deleteUser: (payload) => apiJson.delete(userUrl.user + "/" + payload.id),

  // session
  // school profile
  school: () => apiJson.get(userUrl.school),
  updateSchool: (payload) => apiMultipart.post(userUrl.updateSchool, payload),
  // forgot password
  forgotPassword: (payload) => apiJson.post(userUrl.forgotPassword, payload),
  resetPassword: (payload) => apiJson.post(userUrl.resetPassword, payload),
  //recept
  getReceptData: (payload) => apiJson.post(userUrl.getReceptData, payload),

  // discount
  discount: (payload) => apiJson.post(userUrl.discount, payload),
  addDiscount: (payload) => apiJson.post(userUrl.discount + "/add", payload),
  editDiscount: (payload) =>
    apiJson.put(userUrl.discount + "/" + payload.id, payload.data),
  approveDiscount: (payload) =>
    apiJson.put(userUrl.discount + "/" + payload.id, payload.data),
  deleteDiscount: (payload) =>
    apiJson.delete(userUrl.discount + "/" + payload.id),
  rejectDiscount: (payload) =>
    apiJson.put(userUrl.discount + "/" + payload.id, payload.data),
  changePassword: (payload) => apiJson.post(userUrl.changePassword, payload),
  // payment gateway
  applyPaymentGateWay: (payload) =>
    apiMultipart.post(userUrl.applyForPaymentGateWay, payload),
  paymentGateWayList: () => apiJson.get(userUrl.paymentGateWayList),
  studentPromotion: (payload) =>
    apiJson.post(userUrl.studentPromotion, payload),
};

export const webApi = {
  state: () => apiJson.get(webUrl.state),
  district: (payload) => apiJson.get(webUrl.district + "/" + payload.id),
};
export const adminApi = {
  getOrganisationList: () => apiJson.get(adminUrl.getOrganisationList),
  // plans
  createPlan: (payload) => apiJson.post(adminUrl.createPlan, payload),
  plansList: () => apiJson.get(adminUrl.plans),
  editPlan: (payload) =>
    apiJson.put(adminUrl.plans + "/" + payload.id, payload.data),
  deletePlan: (payload) => apiJson.delete(adminUrl.plans + "/" + payload.id),

  // enquiry
  enquiry: () => apiJson.get(adminUrl.enquiry),
  // bank
  bank: () => apiJson.get(adminUrl.bank),
  addBank: (payload) => apiJson.post(adminUrl.bank + "/add", payload),
  editBank: (payload) =>
    apiJson.put(adminUrl.bank + "/" + payload.id, payload.data),
  deleteBank: (payload) => apiJson.delete(adminUrl.bank + "/" + payload.id),
  // apply pg
  paymentGateWay: () => apiJson.get(adminUrl.paymentGateWay),
  singlePaymentRequest: (payload) =>
    apiJson.get(adminUrl.singlePaymentRequest + "/" + payload.id),
  updatePayment: (payload) =>
    apiJson.put(adminUrl.singlePaymentRequest + "/" + payload.id, payload.data),
};
export default userApi;
